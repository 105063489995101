import Vue from 'vue';
import Vuex from 'vuex';
import VueSuperagent from 'vue-superagent';

Vue.use(Vuex);
Vue.use(VueSuperagent, {
  baseUrl: '/api',
});

const defaultAccount = {
  User: '',
  Name: '',
  Group: '',
  Token: '',
  Lived: '',
};

export default new Vuex.Store({
  state: {
    app: {
      currencies: [],
    },
    account: defaultAccount,
  },
  mutations: {
    signIn(state, account) {
      state.account = account;
    },
    signOut(state) {
      state.account = defaultAccount;
    },
    setCurrencies(state, currencies) {
      state.app.currencies = currencies;
    },
  },
  actions: {
    fetchCurrencies(context) {
      Vue.superagent
        .get('/currency')
        .then((res) => {
          context.commit('setCurrencies', res.body.currencies);
        })
        .catch((err) => {
          alert(`Failed to fetch currencies: ${err.response.text}`);
        });
    },
  },
  modules: {},
});
