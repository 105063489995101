<template>
  <div class="position-relative h-100">
    <loading-overlay v-if="checkingLogin" />
    <navigation v-show="!checkingLogin && !HideNavigation" />
    <div v-show="!checkingLogin">
      <router-view />
    </div>
  </div>
</template>

<script>
const Navigation = () => import('@/components/navigation/Navigation.vue');
const LoadingOverlay = () => import('@/components/LoadingOverlay.vue');

export default {
  name: 'App',
  components: {
    Navigation,
    LoadingOverlay,
  },
  computed: {
    HideNavigation() {
      return this.$route.name === 'AccountLogin' || this.$route.name === 'AccountLogout';
    },
  },
  data() {
    return {
      account: this.$store.state.account,
      checkingLogin: false,
    };
  },
  methods: {
    redirectPage() {
      if (this.$route.name === null) {
        setTimeout(() => {
          this.redirectPage();
        }, 100);
        return;
      }

      const query = {};
      if (this.$route.name !== 'AccountLogin') {
        query.redirect = location.pathname;
      }
      this.$router.push({ name: 'AccountLogin', query }, () => {}, () => {});
    },
    checkLogin() {
      if (this.checkingLogin) return;
      this.checkingLogin = true;
      this.$http
        .get('/account/check_login')
        .then((res) => {
          if ('account' in res.body) {
            this.account = Object.assign(this.account, res.body.account);
            if (this.HideNavigation) {
              this.$router.push('/dashboard');
            }
          }
        })
        .catch(() => {
          this.redirectPage();
        })
        .finally(() => {
          this.checkingLogin = false;
        });
    },
  },
  created() {
    this.checkLogin();
  },
};
</script>

<style lang="scss" scoped>
</style>
