import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue'),
  },
  {
    path: '/account/login',
    name: 'AccountLogin',
    component: () => import(/* webpackChunkName: "AccountLogin" */ '@/views/account/AccountLogin.vue'),
  },
  {
    path: '/account/logout',
    name: 'AccountLogout',
    component: () => import(/* webpackChunkName: "AccountLogout" */ '@/views/account/AccountLogout.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "Admin" */ '@/views/Admin.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard.vue'),
  },
  {
    path: '/pos/:posid',
    name: 'Position',
    component: () => import(/* webpackChunkName: "Position" */ '@/views/Position.vue'),
  },
  {
    path: '/positions',
    name: 'Positions',
    component: () => import(/* webpackChunkName: "Positions" */ '@/views/Positions.vue'),
  },
  {
    path: '/plants',
    name: 'Herbs',
    component: () => import(/* webpackChunkName: "Herbs" */ '@/views/Herbs.vue'),
  },
  {
    path: '/operations',
    name: 'Operations',
    component: () => import(/* webpackChunkName: "Operations" */ '@/views/Operations.vue'),
  },
  {
    path: '/moves',
    name: 'Moves',
    component: () => import(/* webpackChunkName: "Moves" */ '@/views/Moves.vue'),
  },
  {
    path: '/plants/:herbid',
    name: 'Herb',
    component: () => import(/* webpackChunkName: "Herb" */ '@/views/Herb.vue'),
  },
  {
    path: '/plant_groups',
    name: 'HerbGroups',
    component: () => import(/* webpackChunkName: "HerbGroups" */ '@/views/HerbGroups.vue'),
  },
  { path: '*', component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue') },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
